<template>
  <div class="contain">
    <header>
      <a target="_blank"><img src="../assets/h5images/logo.png" alt="Logo"></a>
      <img src="../assets/h5images/head-right.png" class="img-weba">
    </header>

    <div class="main">
      <img src="../assets/h5images/img-main.png" alt="banner" class="img-banner">
      <ul class="list-link">
        <li @click="ChangePage">
          <div class="l-li"><img src="../assets/h5images/map-cn.png" class="icon-contry"><span
              class="mar15px">中国大陆</span><img src="../assets/h5images/icon-line.png"
              class="marr30px icon-arrow"><span>检测完毕</span></div>
          <div class="btn-li"><img src="../assets/h5images/btn-enter.png"></div>
        </li>
        <li @click="ChangePage">
          <div class="l-li"><img src="../assets/h5images/map-hk.png" class="icon-contry"><span
              class="mar15px">中国香港</span><img src="../assets/h5images/icon-line.png"
              class="marr30px icon-arrow"><span>检测完毕</span></div>
          <div class="btn-li"><img src="../assets/h5images/btn-enter.png"></div>
        </li>
        <li @click="ChangePage">
          <div class="l-li"><img src="../assets/h5images/map-macau.png" class="icon-contry"><span
              class="mar15px">中国澳门</span><img src="../assets/h5images/icon-line.png"
              class="marr30px icon-arrow"><span>检测完毕</span></div>
          <div class="btn-li"><img src="../assets/h5images/btn-enter.png"></div>
        </li>
        <li @click="ChangePage">
          <div class="l-li"><img src="../assets/h5images/map-taiwan.png" class="icon-contry"><span
              class="mar15px">中国台湾</span><img src="../assets/h5images/icon-line.png"
              class="marr30px icon-arrow"><span>检测完毕</span></div>
          <div class="btn-li"><img src="../assets/h5images/btn-enter.png"></div>
        </li>
        <li @click="ChangePage">
          <div class="l-li"><img src="../assets/h5images/map-asia.png" class="icon-contry"><span
              class="mar15px">亚洲路线</span><img src="../assets/h5images/icon-line.png"
              class="marr30px icon-arrow"><span>检测完毕</span></div>
          <div class="btn-li"><img src="../assets/h5images/btn-enter.png"></div>
        </li>
        <li @click="ChangePage">
          <div class="l-li"><img src="../assets/h5images/map-inter.png" class="icon-contry"><span
              class="mar15px">国际路线</span><img src="../assets/h5images/icon-line.png"
              class="marr30px icon-arrow"><span>检测完毕</span></div>
          <div class="btn-li"><img src="../assets/h5images/btn-enter.png"></div>
        </li>
      </ul>
      <div class="btn-content">
        <a href="http://29908.com/" target="_blank"><img src="../assets/h5images/btn-ios.png"></a>
        <a href="https://lwesoes.l0hv76mnpf.com/71bcfb4d41cb4cff7b54jkfle-keli14bec0f0b040d000b09080b040808020902050608010f81ed0c0940c147fc795"
          target="_blank"><img src="../assets/h5images/btn-service.png"></a>
        <a href="http://29908.com/" target="_blank"><img src="../assets/h5images/btn-and.png"></a>
      </div>
      <img src="../assets/h5images/img-node.png" class="img-tit">
      <p class="footer">Copyright © 澳門金沙 Reserved</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'h',
  data() {
    return {
    }
  },
  beforeCreate() {
    window.onload = function () {
      /*720代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是
        为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
      getRem(750, 100)
    };
    window.onresize = function () {
      getRem(750, 100)
    };
    function getRem(pwidth, prem) {
      var html = document.getElementsByTagName("html")[0];
      var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
      html.style.fontSize = oWidth / pwidth * prem + "px";
    }
  },
  mounted() {
    setInterval(() => {
      this.changeCheck()
    }, 3000)
  },
  methods: {
    changeCheck() {
      let list = document.querySelectorAll('.check')
      list.forEach(item => {
        item.innerHTML = this.getRandom(30, 60)
      })
    },
    getRandom(n, m) {
      var num = Math.floor(Math.random() * (m - n + 1) + n)
      return num
    },
    ChangePage() {
      let index = this.getRandom(0, 7)
      let list = [
        'https://ge4.51112990.com:14816',
        'https://07s.29992990.com:14816',
        'https://100.30002990.com:14816',
        'https://www.299088333.com',
        'https://www.299088444.com',
        'https://www.299044777.com',
        'https://134.122.219.234:131/cuivxcekcf/pdccauwloxwjtvbfuujsnuncmmoylwymgvkwbbpkkebspmtegu.php',
        'https://66.203.151.126:882/xefdwmqyrl/lqyqcmurwprwyjiheilxgfzemxiscurwhansirsxlygofkewlb.php',
      ]
      window.open(list[index], '_blank')
    }
  }
}
</script>
 <!-- <script src="../assets/js/win.js" type="text/javascript"></script>
<script src="../assets/js/screen.js"></script> -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
html {
  height: 100%;
  max-width: 750px;
  font-family: 'Source Han Sans CN';
  box-sizing: border-box;
  outline: 0;
  -webkit-text-size-adjust: none;
  margin: 0 auto;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  margin: 0;
  padding: 0;
}

a,
input,
textarea,
select,
button {
  outline: 0;
}

a {
  color: #353535;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:active {
  color: #333;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

ul,
p {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #999;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}

.contain {
  // width:100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

header {
  width: 100%;
  height: 1rem;
  background: #af0000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.24rem;
}

header a {
  display: inline-block;
  height: auto;
}

header a img {
  display: block;
  width: 2.9rem;
}

.img-weba {
  width: 2.96rem;
  display: block;
}

.main {
  width: 100%;
  background: url("../assets/h5images/bg-main.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 0.66rem;
}

.img-banner {
  display: block;
  width: 100%;
}

.list-link {
  width: 100%;
  padding: 0 0.24rem;
}

.list-link li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 0.56rem;
  margin: 0 0 0.2rem 0;
  background: url("../assets/h5images/bg-border.png") center center no-repeat;
  background-size: 100% 0.56rem;
  align-items: center;
  cursor: pointer;
}

.l-li {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 0.24rem;
  margin-left: 0.22rem;
}

.icon-contry {
  width: 0.36rem;
  display: block;
}

.icon-arrow {
  width: 0.64rem;
}

.mar15px {
  margin: 0 0.48rem 0 0.16rem;
}

.marr30px {
  margin-right: 0.48rem;
}

.btn-li {
  width: 2.12rem;
  margin-right: 0.1rem;
  // margin:0.1rem 0.1rem 0 0.2rem;
}

.btn-content {
  display: flex;
  justify-content: space-around;
  margin-top: 0.15rem;
  width: 100%;
  padding: 0 0.24rem;
}

.img-tit {
  display: block;
  margin: 0.36rem auto 0;
  width: 7.02rem;
}

.footer {
  text-align: center;
  padding: 0.28rem 0 0.2rem 0;
  font-size: 0.24rem;
}</style>
